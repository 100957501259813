<template>
  <div class="events">
    <loader v-if="GET_EVENTS_LOADER"/>

    <div
        v-if="GET_EVENTS && GET_EVENTS.length"
        class="grid"
    >
      <event
          v-for="event in GET_EVENTS"
          :key="event.id"
          :event="event"
          @click.native="goToEvent(event)"
      />
    </div>

    <div v-else class="events-plug">
      Ничего не найдено
    </div>
  </div>
</template>

<script>

import Tag from "@/components/Parts/Tag";
import Event from "@/components/Parts/EventCard";
import BannerGuestCard from "@/components/Parts/BannerGuestCard";
import Loader from "@/components/Parts/Loader";
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: "Events",
  props: {
    limit: {
      type: Number,
      default: 0
    }
  },

  components: {
    Event,
    Tag,
    BannerGuestCard,
    Loader
  },

  data() {
    return {
      badges: [],
      activeBadge: 0,
      cities: [],
      events: [],
      count: 6,
      page: 1
    };
  },

  computed: {
    ...mapGetters(["GET_EVENTS", "GET_EVENTS_LOADER"]),
  },

  mounted() {
    this.SET_CLEAR_EVENTS();
    this.GET_EVENTS_FROM_SERVER({
      page: this.page,
      count: this.count,
    });
    this.scroll();
  },

  beforeDestroy() {
    this.SET_CLEAR_EVENTS();
  },

  methods: {
    ...mapActions([
      "GET_EVENTS_FROM_SERVER",
    ]),

    ...mapMutations([
      "SET_CLEAR_EVENTS"
    ]),

    goToEvent(event) {
      this.$router.push({path: `/event/${event.id}`});
      this.$store.dispatch("setSelectedEvent", event);
    },

    scroll() {
      const elementContainer = document.querySelector(".events");

      elementContainer.addEventListener("scroll", () => {
        let scrollHeight = elementContainer.scrollHeight;
        if (elementContainer.scrollTop + elementContainer.clientHeight >= scrollHeight) {
          this.page = this.page + 1;
          this.GET_EVENTS_FROM_SERVER({
            count: this.count,
            page: this.page,
          })
        }
      })

    },
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/utils/_variables.scss";

.tags {
  position: absolute;
  bottom: 160px;
  width: 100%;
  background-color: #1e1e1e;
  border-top: 3px solid rgba(255, 255, 255, 0.16);
}

.events {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  height: 1151px;
  overflow-y: auto;
  .background {
    height: 1151px;
    z-index: 100;
  }
  .grid {
    margin: 0 32px;
    margin-top: 32px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .event {
      margin-bottom: 84px;
    }
  }

  .events-plug {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1500px;
    width: 100%;
    font-size: 56px;
  }
}
</style>
