<template>
  <div class="banner-guest-buy">
    <div class="left-side">
      <h1 class="banner-guest-buy__title">Карта гостя</h1>
      <p class="banner-guest-buy__text">
        В ваших руках — всё, что нужно, чтобы вы могли увидеть больше, а заплатить меньше
      </p>
      <button 
        class="banner-guest-buy__detail-btn"
      >
        Купить
        <div class="arrow">
          <arrow class="detail-arrow"/>
        </div>
      </button>
    </div>
    <div class="right-side">
      <img src="@/assets/img/GuestCard.png" />
    </div>
  </div>
</template>

<script>
import Arrow from "@/components/Icons/ArrowRight";

export default {
  name: "BannerGuestCard",
  components: {
    Arrow
  }
}
</script>

<style lang="scss" scoped>
.banner-guest-buy {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 32px;
  background: linear-gradient(219.44deg, #10BD9B -29.91%, #1E85EF 98.39%);
  border-radius: 12px;

  .left-side {
    padding: 44px 32px;
  }

  &__title {
    font-size: 60px;
    font-weight: bold;
    line-height: 60px;
  }

  &__text {
    font-size: 26px;
    line-height: 32px;
  }

  &__detail-btn {
    display: flex;
    align-items: center;
    padding: 11px 12px 11px 26px;
    margin-top: 36px;
    font-size: 26px;
    font-weight: normal;
    line-height: 64px;
    border: none;
    border-radius: 67px;
    outline: none;
    background: rgba(42, 42, 44, 0.4);

    .arrow {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 41px;
      height: 41px;
      margin-left: 24px;
      border-radius: 50%;
      background: rgba(255, 255, 255, 0.2);

      .detail-arrow {
        width: 13px;
        height: 13px;
      }
    }
  }
}

</style>